export default {
  route: {
    home: 'home',
    systemManagement: 'systemManagement',
    userManagement: 'userManagement',
    roleManagement: 'roleManagement',
    authorityManagement: 'authorityManagement',
    codeManagement: 'codeManagement',
    attendanceRecord: 'attendanceRecord',
    attendanceSummary: 'attendanceSummary',
    attendance: 'attendance',
    orgManage: 'orgManage',
    baseSetting: 'baseSetting',
    fundsManage: 'fundsManage',
    organization: 'organization',
    employees: 'employees',
    employeeJoin: 'employeeJoin',
    RecruitmentManagement: 'RecruitmentManagement',
    pcTrackerManagemengt: 'pcTrackerManagemengt',
    salaryManagement: 'salaryManagement',
    payRollManagement: 'payRollManagement',
    PCTrackerMVPManagement: 'PCTrackerMVPManagement',
    ctoApproval: 'ctoApproval',
    IC_Charge: 'IC_Charge',
    TurnDownOfferRecord: 'TurnDownOfferRecord',
    itData: 'IT Data',
    itEmployee: 'Employee',
    itIntern: 'Intern',
    RForm:'R-Form',
  }
}
