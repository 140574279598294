import layout from '@/layout';

export default {
  path: '/dashboard/rform',
  component: layout,
  name: 'RForm',
  redirect: '/dashboard/rform/rformlist',
  meta: {
    title: 'RForm',
    icon: 'user'
  },
  children: [{
    path: '/dashboard/rform/rformlist',
    name: 'RForm',
    // component: () => import('@/views/Dashboard/RForm/rformList.vue'),
  }]
};